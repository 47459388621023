import _ from "underscore";
import { PRODUCTION_HOSTS, STAGING_HOSTS } from './constants'

async function post(payload, email, businessSlug) {
  function serverUrl() {
    let domain = 'https://growth.housecallpro-staging.com'
    if (STAGING_HOSTS.find(host => window.location.host.includes(host))) {
      return domain
    } else if (PRODUCTION_HOSTS.find(host => window.location.host.includes(host))) {
      domain = 'https://growth.housecallpro.com'
    } else if (window.location.host.includes('localhost')) {
      domain = 'http://localhost:3001'
    }
    return domain
  }

  try {
    const url = serverUrl();
    const businessRoute = `business/${businessSlug}/leads/full_form`
    const response = await fetch(`${url}/${businessRoute}`, {
      body: JSON.stringify(payload),
      method: 'POST',
      mode: 'cors',
      headers: {
        'referrer': window.location.host,
        'accept': 'application/json, text/plain, */*',
        'cache-control': 'no-cache',
        'content-type': 'application/json;charset=UTF-8',
      },
    });
    const json = await response.json();
    window.localStorage.setItem('lead_id', JSON.stringify({ data: json?.leadId }));
    window.sessionStorage.setItem('email', email);
    return json;
  } catch (error) {
    console.error(error);
  }
}

export default post
