import post from './post'
import { HCP_BUSINESS_SLUG } from './constants'

export const hcp_leadCapture = {};

export function formatPhoneNumber(phone) {
  return phone
    .replace(/\D/g, '')
    .slice(-10)
    .replace(/^(\d)/, '($1')
    .replace(/^(\(\d{3})(\d)/, '$1) $2')
    .replace(/(\d{3})(\d{1,5})/, '$1-$2')
    .replace(/(-\d+)$/, '$1');
}

(function(window, hcp_leadCapture) {
  function getPayload(leadAttributes) {
    const conversionPage = window.location.href;
    const marketingChannel = window.sessionStorage.getItem('hcp_session_marketingchannel');
    const marketingSubchannel = window.sessionStorage.getItem('hcp_session_marketingsubchannel');
    const httpReferer = window.sessionStorage.getItem('http_external_referer');
    const leadUuid = JSON.parse(window.localStorage.getItem('lead_id'))?.data || '';
    const segmentAnonymousId = JSON.parse(window.localStorage.getItem('ajs_anonymous_id')) || JSON.parse(window.localStorage.getItem('hcp_segment_anonymous_id'))?.data;
    const queryParams = JSON.parse(window.sessionStorage.getItem('queryParams'));
    const googleClickId = queryParams?.gclid;

    if (!leadAttributes.email) {
      throw new Error('Email is required')
    } 

    if (leadAttributes.phone) {
      leadAttributes.phone = formatPhoneNumber(leadAttributes.phone)
    } 

    return {
      lead: leadAttributes,
      marketing_attributes: {
        marketing_channel: marketingChannel,
        lead_type: 'MQL',
        conversion_page: conversionPage,
        google_click_id: googleClickId,
        http_referer: httpReferer,
        marketing_subchannel: marketingSubchannel,
        uuid: leadUuid,
      },
      segment: {
        anonymous_id: segmentAnonymousId,
      },
      query_parameters: queryParams,
    }
  }

  hcp_leadCapture.pushLead = async function({ leadAttributes, businessSlug = HCP_BUSINESS_SLUG }) {
    return await post(getPayload(leadAttributes), leadAttributes.email, businessSlug);
  }

  window.hcp_leadCapture = hcp_leadCapture;
})(window, hcp_leadCapture);
