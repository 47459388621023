export const HCP_BUSINESS_SLUG =  'hcp';
export const CONQUER_BUSINESS_SLUG =  'cq';
export const BUILDBOOK_BUSINESS_SLUG =  'bb';

export const PRODUCTION_HOSTS = [
    'housecallpro.com',
    'buildbook.co',
    'conquernow.com',
    'go.conquernow.com',
]

export const STAGING_HOSTS = [
    'housecallpro-staging.com',
    'buildbook-staging.herokuapp.com',
    'buildbook.webflow.io',
]
